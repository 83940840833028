<template>
  <div id="editar">
    <vx-card title="🏢🚪 Listado de apartamentos en Nivel" title-color="black">
      <p class="mb-1">Puede crear apartamentos para el nivel seleccionado.</p>
      <p>
        Utilice el importador de apartamentos para mayor rapidez de ingreso.
      </p>
    </vx-card>
    <br />
    <EditorApartamento />
    <br />
    <ListadoApartamentos />
  </div>
</template>

<script>
import ListadoApartamentos from "./ListadoApartamentosComponent";
import EditorApartamento from "../../properties/components/UpdatePropertyForm";

export default {
  components: {
    ListadoApartamentos,
    EditorApartamento
  }
};
</script>

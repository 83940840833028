<template>
  <div>
    <div v-show="true" id="data-list-thumb-view" class="data-list-container">
      <vs-table
        ref="table"
        single
        v-model="selected"
        pagination
        :max-items="itemsPerPage"
        :data="getLevel.inventory"
        noDataText="🤦‍♂️ No hay Apartamentos creados..."
      >
        <div slot="header">
          <vs-col vs-type="flex" vs-justify="flex-end">
            <vs-row>
              <router-link :to="{ name: 'Crear Apartamento' }">
                <vs-button
                  class="m-1 mb-4"
                  color="success"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  to="/formulario-de-ingreso-de-proyecto"
                  @click="nivelSeleccionado($route.params.nivelID)"
                  >Apartamento</vs-button
                >
              </router-link>
              <router-link :to="{ name: 'Importador de Apartamentos' }">
                <vs-button
                  color="primary"
                  icon-pack="fas"
                  icon="fa-file-excel"
                  class="mt-1 mb-4"
                  >Importador</vs-button
                >
              </router-link>
              <router-link :to="{ name: 'Importador de Imagenes' }">
                <vs-button
                  color="#ff5400"
                  icon-pack="fas"
                  icon="fa-image"
                  class="m-1 mb-4"
                  >Imagen por Tipo</vs-button
                >
              </router-link>
            </vs-row>
          </vs-col>
        </div>
        <template slot="thead">
          <vs-th>Plano</vs-th>
          <vs-th>ID.</vs-th>
          <vs-th>m2</vs-th>
          <vs-th>Hab.</vs-th>
          <vs-th>Baños</vs-th>
          <vs-th>Jardín</vs-th>
          <vs-th>Precio sin iva</vs-th>
          <vs-th
            >{{ $tc("message.Reservation", 1, user.countryCode) }} Min.</vs-th
          >
          <vs-th>Estado</vs-th>
          <vs-th>Acción</vs-th>
        </template>

        <template>
          <tbody>
            <vs-tr
              :data="apt"
              :key="index"
              v-for="(apt, index) in getLevel.inventory"
            >
              <vs-td class="img-container">
                <img :src="apt.plane_img" class="proyect_Thumbnail" />
              </vs-td>

              <vs-td>
                <p class="product-name font-medium">{{ apt.number }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ apt.living_square_mts }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ apt.bedrooms }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ apt.bathrooms }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ apt.garden }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">
                  Q.
                  {{
                    apt.price
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                </p>
              </vs-td>

              <vs-td>
                <p class="product-category">
                  Q.
                  {{
                    apt.reserve_price
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                </p>
              </vs-td>

              <vs-td>
                <vs-chip :color="statusColor(apt.actual_state)">{{
                  apt.actual_state
                }}</vs-chip>
              </vs-td>

              <vs-td>
                <vs-row>
                  <vs-button
                    color="primary"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-edit"
                    class="p-1"
                    @click.native="editApartament(apt._id)"
                  ></vs-button>
                  <vs-button
                    color="danger"
                    type="flat"
                    icon-pack="feather"
                    icon="icon-trash"
                    class="p-1"
                    @click.native="
                      (popupDeleteApartament = true),
                        (selectedApartamentToDelete = apt._id)
                    "
                  ></vs-button>
                </vs-row>
              </vs-td>
            </vs-tr>
          </tbody>
          <div>
            <vs-popup
              title="Eliminación de unidad"
              :active.sync="popupDeleteApartament"
            >
              <vs-row>
                <h5>
                  ¿Deseas eliminar esta unidad? esta función eliminara la unidad
                  de tu inventario.
                </h5>
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-w="12"
                >
                  <vs-button
                    color="danger"
                    @click.native="deleteApartament(selectedApartamentToDelete)"
                    class="mt-2"
                    icon-pack="feather"
                    icon="icon-trash"
                    >Sí, eliminar</vs-button
                  >
                </vs-col>
              </vs-row>
            </vs-popup>
          </div>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapState } from "vuex";

export default {
  name: "ListadoProyectos",
  data() {
    return {
      selected: [],
      getLevel: [],
      itemsPerPage: 8,
      getData: false,
      levelSelected: "",
      popupDeleteApartament: false,
      selectedApartamentToDelete: ""
    };
  },
  computed: {
    ...mapState("auth", ["user"])
  },
  apollo: {
    getLevel: {
      query: gql`
        query($levelID: String!) {
          getLevel(levelID: $levelID) {
            inventory {
              _id
              number
              plane_img
              living_square_mts
              bedrooms
              bathrooms
              garden
              actual_state
              price
              reserve_price
            }
          }
        }
      `,
      variables() {
        return {
          levelID: this.levelSelected
        };
      },
      pollInterval: 700
    }
  },
  methods: {
    editApartament(aptID) {
      const payload = {
        showEdit: true,
        id: aptID
      };

      this.$store.dispatch("EditarApartamento", payload);
      document.getElementById("editar").scrollIntoView();
    },
    statusColor(status) {
      if (status == "Disponible") return "success";
      if (status == "Reservado") return "danger";
      if (status == "Bloqueado") return "dark";
    },
    deleteApartament(ID) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation(
              $levelID: String!
              $apartamentID: String!
              $developerID: String!
            ) {
              deleteApartament(
                levelID: $levelID
                apartamentID: $apartamentID
                developerID: $developerID
              ) {
                _id
              }
            }
          `,
          // Parametros que vienen de mi model
          variables: {
            apartamentID: ID,
            levelID: localStorage.selectedLevel,
            developerID: localStorage.company_id
          }
        })
        .then(() => {
          this.$vs.loading.close("#button-with-loading > .con-vs-loading");
          this.$vs.notify({
            time: 4000,
            title: "Eliminación correcta",
            text: "Apartamento eliminado del nivel.",
            color: "success",
            iconPack: "feather",
            icon: "icon-trash"
          });
          this.popupDeleteApartament = false;
        })
        .catch(() => {
          //console.error(error)
        });
    },
    nivelSeleccionado(params) {
      localStorage.nivelID = params;
    }
  },
  mounted() {
    this.levelSelected = localStorage.selectedLevel;
  }
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 0px;
      margin-right: 0px;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: left;
            }

            .proyect_Thumbnail {
              height: 110px;
            }
          }
        }
        td.td-check {
          padding: 10px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
    .dropdown-button-container {
      display: flex;
      align-items: center;

      .btnx {
        border-radius: 5px 0px 0px 5px;
      }

      .btn-drop {
        border-radius: 0px 5px 5px 0px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
  }
}
</style>
